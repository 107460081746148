import { atom, useRecoilState } from "recoil";

const userDatabaseIdAtom = atom<number>({
  key: "USER_DATABASE_ID_DATA",
  default: -1,
});

export default function useUserDatabaseId() {
  const [state, setter] = useRecoilState(userDatabaseIdAtom);

  function set(influencerId: number): void {
    setter(influencerId);
  }

  return {
    state,
    set,
  };
}
