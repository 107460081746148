import { commitMutation, graphql } from "react-relay/hooks";
import { onLogoutMutation } from "@generated/onLogoutMutation.graphql";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import reloadApp from "@lib/util/realodApp";
import { sendError } from "@lib/util/logger";
import ExceptionError from "@lib/errors/exceptionError";
import RelayEnvironment from "../../RelayEnvironment";

const mutation = graphql`
  mutation onLogoutMutation($input: LogOutMutationInput!) {
    logOut(input: $input) {
      success
    }
  }
`;

export async function removeStorage(): Promise<void> {
  await SecureStoreManager.deleteAccessTokenSet();
  await reloadApp();
}

export default async function onLogout(): Promise<void> {
  await new Promise<void>((resolve) => {
    commitMutation<onLogoutMutation>(RelayEnvironment, {
      mutation,
      variables: {
        input: {},
      },
      onCompleted: () => {
        resolve();
      },
      onError: (err) => {
        sendError(new ExceptionError("onLogout error", err));
        resolve();
      },
    });
  });
  await SecureStoreManager.removeAndReload();
}
