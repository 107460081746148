import * as SplashScreen from "expo-splash-screen";
import React, { useCallback } from "react";
import useAccount from "@hooks/useAccount";
import useSound from "@hooks/useSound";
import initializeUser from "@lib/util/initializeUser";
import { Audio } from "expo-av";
import { start } from "@lib/util/analytics";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import { sendError } from "@lib/util/logger";
import ExceptionError from "@lib/errors/exceptionError";
import useUserDatabaseId from "./useUserDatabaseId";
// import { chatSound } from "@components/atoms/AssetImage";

/**
 * 効果音オブジェクトをロードし、再利用可能なようにRecoildに管理する
 */
async function loadPopSound(): Promise<Audio.Sound | null> {
  try {
    // TODO: ロードできない
    // const { sound } = await Audio.Sound.createAsync(chatSound);
    // return sound;
    return null;
  } catch (e: unknown) {
    sendError(new ExceptionError("loadPopSound error", e));
    return null;
  }
}

SplashScreen.preventAutoHideAsync();

export default function useInitializeApp() {
  const [isLoadingComplete, setLoadingComplete] =
    React.useState<boolean>(false);
  const [initializing, setInitializing] = React.useState<boolean>(false);
  const { set: setSound } = useSound();
  const { set: setInfluencerId } = useAccount();
  const { set: setUserDatabaseId } = useUserDatabaseId();

  const initialize = useCallback(async () => {
    if (initializing) {
      return;
    }
    try {
      setInitializing(true);
      const popSound = await loadPopSound();
      if (popSound !== null) {
        setSound(popSound);
      }
      const token = await SecureStoreManager.getAccessToken();
      if (token === null) {
        return;
      }
      const { id, databaseId } = await initializeUser();
      setInfluencerId(id);
      setUserDatabaseId(databaseId);
      await start();
    } catch (e: unknown) {
      sendError(
        new ExceptionError("initialize error on useInitializeApp.ts", e)
      );
    } finally {
      setLoadingComplete(true);
      SplashScreen.hideAsync();
    }
  }, [initializing, setInfluencerId, setSound, setUserDatabaseId]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  return isLoadingComplete;
}
