import React, { useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import { Card } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import Modal from "@components/molecules/Modal";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import Dialog from "@components/molecules/dialog/Dialog";
import { graphql, useMutation } from "react-relay/hooks";
import Form from "@components/organisms/AuthSnsSignup/AuthSnsSignupForm";
import Confirm from "@components/organisms/AuthSnsSignup/AuthSnsSignupConfirm";
import Password from "@components/organisms/AuthSnsSignup/AuthSnsSignupPassword";
import recordConversion from "@lib/util/recordConversion";
import useAccount from "@hooks/useAccount";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import initializeUser from "@lib/util/initializeUser";
import { replace } from "@navigation/navigate";
import { AuthSnsSignupSignupMutation } from "@generated/AuthSnsSignupSignupMutation.graphql";
import { AuthSnsSignupResendMutation } from "@generated/AuthSnsSignupResendMutation.graphql";
import { AuthSnsSignupConfirmMutation } from "@generated/AuthSnsSignupConfirmMutation.graphql";
import { AuthSnsSignupReissueMutation } from "@generated/AuthSnsSignupReissueMutation.graphql";
import { AuthSnsSignupLoginMutation } from "@generated/AuthSnsSignupLoginMutation.graphql";
import { resolveError } from "@lib/util/error";
import useUserDatabaseId from "@hooks/useUserDatabaseId";
import { SnsSignupUser } from "../../types";

const signupQuery = graphql`
  mutation AuthSnsSignupSignupMutation($input: SignupSnsMutationInput!) {
    signupSns(input: $input) {
      __typename
      ... on SignupSnsToken {
        uid
        token
      }
      ... on SignupSnsLogin {
        snsType
        snsId
      }
      ... on SignupSnsError {
        message
        errorType
      }
    }
  }
`;

const reissueQuery = graphql`
  mutation AuthSnsSignupReissueMutation($input: ReissuePasswordMutationInput!) {
    reissuePassword(input: $input) {
      email
    }
  }
`;

const loginQuery = graphql`
  mutation AuthSnsSignupLoginMutation($input: LoginWithSnsIdMutationInput!) {
    loginWithSnsId(input: $input) {
      __typename
      ... on LoginWithSnsIdToken {
        token
        refreshToken
      }
      ... on LoginWithSnsIdError {
        message
      }
    }
  }
`;

const resendQuery = graphql`
  mutation AuthSnsSignupResendMutation(
    $input: SignupRegenerateCodeMutationInput!
  ) {
    signupRegenerateCode(input: $input) {
      __typename
      ... on SignupRegenerateCodeToken {
        token
      }
      ... on SignupRegenerateCodeError {
        message
      }
    }
  }
`;

const confirmQuery = graphql`
  mutation AuthSnsSignupConfirmMutation($input: SignupConfirmMutationInput!) {
    signupConfirm(input: $input) {
      __typename
      ... on SignupConfirmSuccess {
        token
        refreshToken
      }
      ... on SignupConfirmError {
        message
      }
    }
  }
`;

type Account = {
  uid: string;
  email: string;
  token: string;
};

type SnsAccount = {
  email: string;
  snsType: string;
  snsId: string;
};

type Props = {
  data: SnsSignupUser;
};

export default function AuthSnsSignup({ data }: Props) {
  const [commitSignup] = useMutation<AuthSnsSignupSignupMutation>(signupQuery);
  const [commitResend] = useMutation<AuthSnsSignupResendMutation>(resendQuery);
  const [commitConfirm] =
    useMutation<AuthSnsSignupConfirmMutation>(confirmQuery);
  const [commitReissue] =
    useMutation<AuthSnsSignupReissueMutation>(reissueQuery);
  const [commitLogin] = useMutation<AuthSnsSignupLoginMutation>(loginQuery);
  const [account, setAccount] = useState<Account | null>(null);
  const [sns, setSns] = useState<SnsAccount | null>(null);
  const [confirmReissue, setConfirmReissue] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const { set: setInfluencerId } = useAccount();
  const { set: setUserDatabaseId } = useUserDatabaseId();

  const logined = useCallback(
    async (params: { refreshToken: string; token: string }) => {
      await SecureStoreManager.registerAccessTokenSet(
        params.token,
        params.refreshToken
      );
      const { id, databaseId } = await initializeUser();
      await recordConversion();
      setSns(null);
      setAccount(null);
      setInfluencerId(id);
      setUserDatabaseId(databaseId);
      replace("SignupThanks");
    },
    [setInfluencerId, setUserDatabaseId]
  );

  const login = useCallback(
    async (password: string) => {
      try {
        if (sns === null) {
          return;
        }
        const result = await new Promise<{
          refreshToken: string;
          token: string;
        }>((resolve, reject) => {
          commitLogin({
            variables: {
              input: {
                id: data.id,
                email: sns.email,
                snsType: sns.snsType,
                snsId: sns.snsId,
                password,
              },
            },
            onCompleted({ loginWithSnsId }) {
              if (loginWithSnsId.__typename === "LoginWithSnsIdToken") {
                resolve(loginWithSnsId);
              } else {
                reject(
                  new Error(
                    loginWithSnsId.__typename === "LoginWithSnsIdError"
                      ? loginWithSnsId.message
                      : "サインアップに失敗しました。"
                  )
                );
              }
            },
          });
        });
        await logined(result);
      } catch (e: unknown) {
        setError(resolveError(e).message);
      }
    },
    [commitLogin, data, logined, sns]
  );

  const reissue = useCallback(async () => {
    if (sns === null) {
      return;
    }
    await new Promise<void>((resolve) => {
      commitReissue({
        variables: {
          input: {
            email: sns.email,
          },
        },
        onCompleted() {
          resolve();
        },
      });
    });
    setConfirmReissue(false);
    setMessage(`${sns.email}にパスワード再発行用のURLを送信しました。`);
  }, [commitReissue, sns]);

  const signUp = useCallback(
    async (params: { name: string; email: string }) => {
      await new Promise<void>((resolve, reject) => {
        commitSignup({
          variables: {
            input: {
              id: data.id,
              name: params.name,
              email: params.email,
              snsType: data.sns_type,
            },
          },
          onCompleted({ signupSns }) {
            if (signupSns.__typename === "SignupSnsToken") {
              setAccount({
                email: params.email,
                uid: signupSns.uid,
                token: signupSns.token,
              });
              resolve();
            } else if (signupSns.__typename === "SignupSnsLogin") {
              setSns({
                email: params.email,
                snsType: signupSns.snsType,
                snsId: signupSns.snsId,
              });
              resolve();
            } else {
              reject(
                new Error(
                  signupSns.__typename === "SignupSnsError"
                    ? signupSns.message
                    : "サインアップに失敗しました。"
                )
              );
            }
          },
        });
      });
    },
    [commitSignup, data]
  );

  const resend = useCallback(async () => {
    try {
      if (account === null) {
        return;
      }
      const token = await new Promise<string>((resolve, reject) => {
        commitResend({
          variables: {
            input: {
              email: account.email,
              uid: account.uid,
            },
          },
          onCompleted({ signupRegenerateCode }) {
            if (
              signupRegenerateCode.__typename === "SignupRegenerateCodeToken"
            ) {
              resolve(signupRegenerateCode.token);
            } else {
              reject(
                signupRegenerateCode.__typename === "SignupRegenerateCodeError"
                  ? signupRegenerateCode.message
                  : "サインアップに失敗しました。"
              );
            }
          },
        });
      });
      setAccount({
        ...account,
        token,
      });
    } catch (e: unknown) {
      setError(resolveError(e).message);
      setAccount(null);
    }
  }, [account, commitResend]);

  const confirm = useCallback(
    async (code: string) => {
      if (account === null) {
        return;
      }
      const result = await new Promise<{ refreshToken: string; token: string }>(
        (resolve, reject) => {
          commitConfirm({
            variables: {
              input: {
                token: account.token,
                code,
              },
            },
            onCompleted({ signupConfirm }) {
              if (signupConfirm.__typename === "SignupConfirmSuccess") {
                resolve(signupConfirm);
              } else {
                reject(
                  new Error(
                    signupConfirm.__typename === "SignupConfirmError"
                      ? signupConfirm.message
                      : "サインアップに失敗しました。"
                  )
                );
              }
            },
          });
        }
      );

      await logined(result);
    },
    [account, commitConfirm, logined]
  );

  return (
    <Card style={styles.container}>
      <NavigationBar title="アカウント登録" />
      <Form initialData={data} signUp={signUp} />

      {(sns !== null || account !== null) && (
        <Modal
          animationType="none"
          header
          headerTitle={
            sns !== null
              ? "ログインを行ってください"
              : "認証コードを送信しました"
          }
          onRequestClose={() => {
            setAccount(null);
            setSns(null);
          }}
          visible
        >
          {sns !== null ? (
            <Password
              onLogin={login}
              onReissue={() => setConfirmReissue(true)}
            />
          ) : account !== null ? (
            <Confirm confirm={confirm} email={account.email} resend={resend} />
          ) : null}
          {confirmReissue && (
            <Dialog
              buttons={[
                { title: "閉じる", type: "white" },
                {
                  title: "再発行",
                  type: "blueGradient",
                  onPress: () => reissue(),
                },
              ]}
              customStyle={{ messageStyle: styles.dialogError }}
              message="パスワードを再発行しますか？"
              onClose={() => setConfirmReissue(false)}
              subline="ご登録のEmailに再発行用のURLを送信します。"
            />
          )}
          {message !== null && (
            <Dialog message={message} onClose={() => setMessage(null)} />
          )}
          {error !== null && (
            <Dialog
              customStyle={{ messageStyle: styles.dialogError }}
              message={error}
              onClose={() => setError(null)}
            />
          )}
        </Modal>
      )}
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
    backgroundColor: Colors.gray10,
  },
  text: {
    color: Colors.gray,
    ...Fonts.lr130,
  },
  form: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  guide: {
    color: Colors.gray,
    ...Fonts.sr140,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  dialogError: {
    color: Colors.orange,
    textAlign: "center",
  },
});
