import { atom, useRecoilState } from "recoil";
import { setUser } from "@lib/util/analytics";
import { decodeId } from "@lib/util/relaySupport";
import * as Logger from "@lib/util/logger";

const accountAtom = atom<string>({
  key: "ACCOUNT_DATA",
  default: "",
});

export default function useAccount() {
  const [state, setter] = useRecoilState(accountAtom);

  function set(influencerId: string): void {
    const decodedInfluencerId = decodeId(influencerId);
    setUser(decodedInfluencerId);
    Logger.setUser({
      id: decodedInfluencerId,
    });
    setter(influencerId);
  }

  return {
    state,
    set,
  };
}
