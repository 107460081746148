import React, { useMemo } from "react";
import days from "dayjs";
import { Text, View } from "@components/atoms/Themed";
import { StyleSheet, Image } from "react-native";
import SNSIcons from "@components/atoms/SNSIcon";
import Fonts from "@constants/Fonts";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import Tag from "@components/atoms/Tag";
import campaignBanner from "@lib/util/campaignBanner";
import { deadlineOfDraft } from "@lib/util/date";
import { graphql, useFragment } from "react-relay/hooks";
import { TodoCampaign$key } from "@generated/TodoCampaign.graphql";
import _ from "lodash";

const dateFormat = "M月D日";

const todoCampaign = graphql`
  fragment TodoCampaign on Post {
    draftedAt
    status
    campaign {
      parentMediaType
      title
      banners {
        file
      }
      socialNetwork {
        postStartOn
        castEndAt
      }
    }
  }
`;

type StatusContent = {
  color: string;
  text: string;
  guide?: string | null;
};

export default function TodoCampaign({
  fragmentRef,
}: {
  fragmentRef: TodoCampaign$key;
}) {
  const data = useFragment(todoCampaign, fragmentRef);
  const publishText = useMemo<string>(() => {
    if (data.campaign == null || data.campaign.socialNetwork == null) {
      return "";
    }

    const { parentMediaType } = data.campaign;
    if (parentMediaType === "cast") {
      return days(data.campaign.socialNetwork.castEndAt).format(dateFormat);
    }
    if (parentMediaType === "other") {
      return days(data.campaign.socialNetwork.postStartOn).format(dateFormat);
    }
    return days(data.campaign.socialNetwork.postStartOn).format(dateFormat);
  }, [data]);

  const statusContent = useMemo<StatusContent>(() => {
    const draftDate =
      data.campaign != null && data.campaign.socialNetwork != null
        ? deadlineOfDraft(
            data.draftedAt,
            data.campaign.socialNetwork.postStartOn
          )
        : null;

    switch (data.status) {
      case "wait_product_shipped":
        return {
          color: Colors.gray,
          text: "商品発送",
        };
      case "product_shipped":
        return {
          color: Colors.green,
          text: "受取報告",
        };
      case "wait_visitation":
        return {
          color: Colors.green,
          text: "指定場所への訪問",
        };
      case "draft":
        return {
          color: Colors.green,
          text: "下書き作成",
          guide: draftDate !== null ? `〜 ${draftDate.format(dateFormat)}` : "",
        };
      case "admin_rejected":
      case "agency_rejected":
      case "draft_with_reject":
        return {
          color: Colors.green,
          text: "下書き修正",
          guide: "下書きを修正してください",
        };
      case "wait_admin_review":
      case "wait_agency_review":
        return {
          color: Colors.gray,
          text: "下書きレビュー中",
        };
      case "wait_publish":
        return {
          color: Colors.green,
          text: "完了報告待ち",
          guide: `${publishText} 〜`,
        };
      case "wait_agency_confirm_report":
        return {
          color: Colors.gray,
          text: "投稿確認中",
        };
      case "finished":
      default:
        return {
          color: Colors.gray,
          text: "お疲れ様でした",
        };
    }
  }, [data, publishText]);

  const bannerSource = useMemo(
    () =>
      data.campaign != null && data.campaign.banners != null
        ? campaignBanner(data.campaign.banners)
        : null,
    [data.campaign]
  );

  return (
    <View style={styles.todoInfo}>
      {bannerSource != null && (
        <Image source={bannerSource} style={styles.image} />
      )}
      <Spacer width={16} />
      <View style={[styles.campaignExplanation]}>
        <View style={styles.head}>
          <Tag
            backgroundColor={statusContent.color}
            color={statusContent.color}
          >
            <Text style={[styles.status]}>{statusContent.text}</Text>
          </Tag>
          {!_.isNil(statusContent.guide) && (
            <>
              <Spacer width={8} />
              <Text
                style={[
                  styles.todoDeadlineText,
                  { color: statusContent.color },
                ]}
              >
                {statusContent.guide}
              </Text>
            </>
          )}
        </View>
        <Spacer height={8} />
        <View style={styles.title}>
          <SNSIcons
            name={data.campaign.parentMediaType}
            style={styles.platform}
          />
          <Spacer width={8} />
          <View style={styles.name}>
            <Text numberOfLines={2} style={styles.explanation}>
              {data.campaign.title}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
  },
  todoInfo: {
    flexDirection: "row",
  },
  image: {
    height: 54,
    width: 72,
    borderRadius: 4,
  },
  imageGr: {
    position: "absolute",
    top: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    borderRadius: 4,
  },
  campaignExplanation: {
    flex: 1,
  },
  head: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  status: {
    ...Fonts.sb100,
    color: Colors.white,
    textAlignVertical: "center",
  },
  title: {
    flexDirection: "row",
    justifyContent: "flex-start",
    flex: 1,
  },
  todoDeadlineText: {
    flexDirection: "row",
    textAlignVertical: "center",
    ...Fonts.lb130,
    color: Colors.green,
  },
  icon: {
    width: 24,
    overflow: "hidden",
  },
  platform: {
    height: 24,
    width: 24,
  },
  name: {
    flex: 1,
  },
  explanation: {
    color: Colors.black,
    textAlign: "left",
    ...Fonts.lr130,
  },
});
