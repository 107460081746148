import React from "react";
import { Text, Card, Wrapper, View } from "@components/atoms/Themed";
import Ripple from "@components/atoms/Ripple";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { StyleSheet } from "react-native";
import { navigate } from "@navigation/navigate";

type Props = {
  current: "working" | "recruiting";
};

export default function NavigationTodo({ current }: Props) {
  const routes = [
    { key: "working", name: "workings" as const, title: "進行中" },
    { key: "recruiting", name: "recruiting" as const, title: "応募中" },
  ];

  return (
    <Card>
      <Wrapper>
        <View style={styles.container}>
          {routes.map((route) => {
            const isFocused = current === route.key;

            return (
              <Ripple
                key={`tabbr-${route.key}`}
                onPress={() =>
                  navigate("Root", {
                    screen: "Todo",
                    params: { screen: route.name },
                  })
                }
                rippleColor={isFocused ? Colors.green : Colors.black0}
                style={[
                  styles.item,
                  isFocused
                    ? {
                        borderColor: Colors.black0,
                        borderBottomColor: Colors.green,
                        borderBottomWidth: 2,
                      }
                    : null,
                ]}
              >
                <Text
                  style={[
                    styles.text,
                    isFocused
                      ? {
                          ...Fonts.xlb100,
                          color: Colors.green,
                        }
                      : null,
                  ]}
                >
                  {route.title}
                </Text>
              </Ripple>
            );
          })}
        </View>
      </Wrapper>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 44,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: Colors.black,
    textAlign: "center",
    ...Fonts.xlr100,
  },
});
