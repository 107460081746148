import {
  sentryDsn,
  env,
  appVersion,
  slackWebhookUrl,
  runtimeVersion,
} from "@constants/Env";
import { Platform } from "react-native";
import * as Device from "expo-device";
import axios from "axios";
import * as Sentry from "sentry-expo";
import { User, CaptureContext, SeverityLevel } from "@sentry/types";
import { ReactNativeWrapperOptions } from "@sentry/react-native/dist/js/options";
import ExceptionError from "@lib/errors/exceptionError";

Sentry.init({
  dsn: sentryDsn,
  tracesSampleRate: 1.0,
  enableInExpoDevelopment: true,
  enableNative: env !== "local",
});

export function wrap<P>(
  RootComponent: React.ComponentType<P>,
  options?: ReactNativeWrapperOptions
) {
  if (Platform.OS === "web") {
    return RootComponent;
  }
  return Sentry.Native.wrap(RootComponent as any, options);
}

export function setUser(user: User | null) {
  if (Platform.OS === "web") {
    return Sentry.Browser.setUser(user as any);
  }
  return Sentry.Native.setUser(user as any);
}

export function log(
  level: SeverityLevel,
  exception: any,
  captureContext?: CaptureContext
) {
  if (captureContext === undefined) {
    captureContext = { level };
  }
  if (level !== "error") {
    if (Platform.OS === "web") {
      return Sentry.Browser.captureMessage(exception, captureContext as any);
    }
    return Sentry.Native.captureMessage(exception, captureContext as any);
  }
  if (Platform.OS === "web") {
    return Sentry.Browser.captureException(exception, captureContext as any);
  }
  return Sentry.Native.captureException(exception, captureContext as any);
}

export async function sendError(
  error: ExceptionError,
  captureContext?: { extra: Record<string, unknown> }
) {
  log("error", error, captureContext);
}

export async function notify(error: any) {
  if (slackWebhookUrl) {
    try {
      const deviceInfo = {
        os: Device.osName,
        osVersion: Device.osVersion,
        modelName: Device.modelName,
        platform: Platform.OS,
        env,
        appVersion,
        runtimeVersion,
      };

      // エラーと端末情報をコードブロックで囲む
      const payload = {
        text: [
          "Client Error Occurred:",
          "```",
          JSON.stringify(error, null, 2),
          "```",
          "Device Info:",
          "```",
          JSON.stringify(deviceInfo, null, 2),
          "```",
        ].join("\n"),
      };

      await axios.post(slackWebhookUrl, JSON.stringify(payload));
    } catch (e) {
      console.error("Failed to send error notification to Slack:", e);
    }
  }
}
