/**
 * recoilをfunctional componentから切り離して使うための対応
 * GraphQL通信の例外エラーのハンドリングに使用する。
 * https://github.com/facebookexperimental/Recoil/issues/289#issuecomment-777249693
 */

import * as recoil from "recoil";
import { useRecoilCallback, atom, useRecoilState } from "recoil";
import { sendError } from "@lib/util/logger";
import ExceptionError from "@lib/errors/exceptionError";

type ErrorCodeType =
  | "Upgrade"
  | "Maintenance"
  | "ServiceDown"
  | "Expired"
  | "NetworkError"
  | "Unknown";

type ErrorType = {
  code?: ErrorCodeType;
  message?: string;
};

export const errorAtom = atom<ErrorType | null>({
  key: "EXCEPTION_ERROR",
  default: null,
});

export default function useExecptionalError() {
  const [state, setError] = useRecoilState(errorAtom);

  return {
    state,
    set: (data: ErrorType | null): void => {
      if (data !== null && data.code === "ServiceDown") {
        sendError(new ExceptionError(data.code, data.message));
      }
      setError(data);
    },
  };
}

// eslint-disable-next-line import/no-mutable-exports
export let setExceptionalError: <T>(
  recoilVal: recoil.RecoilState<T>,
  valOrUpdater: ((currVal: T) => T) | T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => void = null as any;

export function ExceptionalError() {
  useRecoilCallback(({ set }) => {
    setExceptionalError = set;
    return async () => {};
  })();

  return null;
}
