export default class ExceptionError extends Error {
  cause: unknown;

  constructor(message: string, cause: unknown) {
    super(message);
    this.name = this.constructor.name;
    if (cause instanceof Error) {
      this.cause = cause;
    }
  }
}
