import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import Loading from "@components/atoms/Loading";
import { Text } from "@components/atoms/Themed";
import { graphql, useMutation } from "react-relay/hooks";
import {
  OAuthLinkingMutation,
  LinkSnsTypes,
} from "@generated/OAuthLinkingMutation.graphql";
import { makeRedirectUri } from "@lib/util/oauth";
import Spacer from "@components/atoms/Spacer";
import { sendError } from "@lib/util/logger";
import Fonts from "@constants/Fonts";
import ExceptionError from "@lib/errors/exceptionError";

const linkingQuery = graphql`
  mutation OAuthLinkingMutation($input: LinkSnsMutationInput!) {
    linkSns(input: $input) {
      __typename
      ... on Influencer {
        linkingInstagram
        linkingTwitter
        lineId
        appleId
        profile {
          twitterFollowers
          twitterId
          instaFollowers
          instaId
          instaUrl
        }
      }
      ... on LinkSnsError {
        message
      }
    }
  }
`;

type Props = {
  code: string;
  codeVerifier: string;
  platform: LinkSnsTypes;
  onError: (message?: string) => void;
  onSuccess: () => void;
};

export default function OAuthLinking({
  code,
  codeVerifier,
  platform,
  onError,
  onSuccess,
}: Props) {
  const [start, setStart] = useState(false);
  const [commit] = useMutation<OAuthLinkingMutation>(linkingQuery);
  const linking = useCallback(async () => {
    try {
      await new Promise<void>((resolve, reject) => {
        commit({
          variables: {
            input: {
              type: platform,
              codeExchange: code,
              codeVerifier,
              redirectUri: makeRedirectUri(),
            },
          },
          onCompleted({ linkSns }) {
            if (linkSns.__typename === "Influencer") {
              resolve();
            } else {
              reject(
                new Error(
                  linkSns.__typename === "LinkSnsError"
                    ? linkSns.message
                    : `${platform}連携に失敗しました`
                )
              );
            }
          },
          onError() {
            reject(new Error(`${platform}連携に失敗しました`));
          },
        });
      });
      onSuccess();
    } catch (e: unknown) {
      const errorMessage =
        e instanceof Error ? e.message : `${platform}連携に失敗しました`;
      sendError(new ExceptionError("OAuthLinking error", e));
      onError(errorMessage);
    }
  }, [code, codeVerifier, commit, onError, onSuccess, platform]);

  useEffect(() => {
    if (!start) {
      setStart(true);
      linking();
    }
  }, [start, linking]);

  return (
    <>
      <Loading />
      <Spacer height={16} />
      <Text style={styles.text}>連携中...</Text>
    </>
  );
}

const styles = StyleSheet.create({
  text: {
    ...Fonts.xlb100,
  },
});
