import {
  Environment,
  Network,
  RecordSource,
  Store,
  RequestParameters,
  Variables,
  CacheConfig,
  UploadableMap,
} from "relay-runtime";
import axios from "axios";
import type {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";
import _ from "lodash";
import { apiEndpoint, appVersion } from "@constants/Env";
import { setExceptionalError, errorAtom } from "@hooks/useExecptionalError";
import refreshToken from "@lib/util/refreshToken";
import { SecureStoreManager } from "@lib/util/secureStoreManager";
import FormData from "form-data";
import { Platform } from "react-native";
import { resolveError } from "@lib/util/error";
import ExceptionError from "@lib/errors/exceptionError";
import { sendError } from "@lib/util/logger";

type ErrorCodeType =
  | "Maintenance"
  | "NetworkError"
  | "ServiceDown"
  | "Upgrade"
  | "Expired"
  | "Unknown";

type RequestInfo = {
  url: string;
  method: string;
  headers: Record<string, string>;
  params?: Record<string, unknown>;
  query?: string;
  variables?: Variables;
  timestamp: string;
  appVersion: string;
  data?: unknown;
  timeout?: number;
  baseURL?: string;
};

type DetailedErrorType = {
  code: ErrorCodeType;
  message: string;
  request?: RequestInfo;
  response?: AxiosResponse<unknown, unknown>;
  timestamp?: string;
  stack?: string;
  name?: string;
  severity?: "fatal" | "error" | "warning" | "info" | "debug";
  additionalInfo?: {
    networkInfo?: {
      online?: boolean;
    };
    environment?: {
      userAgent?: string;
      platform?: string;
      language?: string;
    };
  };
  error: unknown;
};

type ResponseError = {
  message: string;
  locations: {
    [index: string]: unknown;
  }[];
  path: unknown[];
  extensions: {
    code: string;
  };
};

type ApiResponse<T = object> = {
  errors?: ResponseError[];
  data: T;
};

type Maintenance = {
  code: string;
  message: string;
};

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

const collectRequestInfo = (
  config: InternalAxiosRequestConfig,
  query?: string,
  variables?: Variables
): RequestInfo => ({
  url: config.url ?? apiEndpoint,
  method: (config.method?.toUpperCase() ?? "POST") as string,
  headers: _.omit(config.headers as Record<string, string>, ["Authorization"]),
  params: config.params as Record<string, unknown>,
  query,
  variables,
  timestamp: new Date().toISOString(),
  appVersion: appVersion ?? "unknown",
  data: config.data,
  timeout: config.timeout,
  baseURL: config.baseURL,
});

const handleSetError = async (error: DetailedErrorType): Promise<void> => {
  try {
    const networkInfo = {
      online: typeof navigator !== "undefined" ? navigator.onLine : undefined,
    };

    const enhancedError = {
      ...error,
      timestamp: new Date().toISOString(),
      name: error.code,
      additionalInfo: {
        networkInfo,
        environment: {
          userAgent:
            typeof navigator !== "undefined" ? navigator.userAgent : undefined,
          platform:
            typeof navigator !== "undefined" ? navigator.platform : undefined,
          language:
            typeof navigator !== "undefined" ? navigator.language : undefined,
        },
      },
    };

    setExceptionalError(errorAtom, enhancedError);

    // ExceptionErrorを作成してsendErrorを使用
    const exceptionError = new ExceptionError(error.message, {
      name: error.code,
      cause: error.error,
    });

    await sendError(exceptionError, {
      extra: {
        code: error.code,
        request: error.request,
        response: error.response,
        additionalInfo: enhancedError.additionalInfo,
        timestamp: enhancedError.timestamp,
        severity:
          error.severity ?? (error.code === "Unknown" ? "error" : "warning"),
      },
    });
  } catch (e) {
    // エラーハンドリング自体のエラーを処理
    // eslint-disable-next-line no-console
    console.error("Error in handleSetError:", e);

    const handlerError = new ExceptionError("Error handling failed", {
      name: "ErrorHandlerFailure",
      cause: e,
    });

    await sendError(handlerError, {
      extra: {
        originalError: {
          code: error.code,
          message: error.message,
        },
      },
    });
  }
};

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };
    newConfig.headers.set("AppVersion", appVersion);
    const token = await SecureStoreManager.getAccessToken();
    if (token !== null) {
      newConfig.headers.set("Authorization", `Bearer ${token}`);
    }
    return newConfig;
  }
);

axiosInstance.interceptors.response.use(
  async (response: AxiosResponse<ApiResponse>) => {
    if (response.data.errors != null) {
      const error = _.head(response.data.errors);
      if (error?.extensions?.code === "TokenExpired") {
        return retryWithtokenRefresh(response);
      }
      await handleError(
        error,
        response.config as InternalAxiosRequestConfig,
        response
      );
    }
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 503) {
      const { data } = error.response as AxiosResponse<Maintenance>;
      if (data.code === "UnderMaintenance") {
        await handleSetError({
          code: "Maintenance",
          message: data.message,
          request:
            error.config != null
              ? collectRequestInfo(error.config as InternalAxiosRequestConfig)
              : undefined,
          response: error.response,
          error,
          severity: "warning",
        });
        return;
      }
    }
    const result = resolveError(error);
    await handleSetError({
      code: result.code === "ERR_NETWORK" ? "NetworkError" : "ServiceDown",
      message: result.message,
      request:
        error.config != null
          ? collectRequestInfo(error.config as InternalAxiosRequestConfig)
          : undefined,
      response: error.response,
      error,
      severity: "error",
    });
    throw error;
  }
);

async function fetchGraphQL(text: string, variables: Variables) {
  const { data }: { data: AxiosResponse<ApiResponse> } =
    await axiosInstance.post(
      apiEndpoint,
      JSON.stringify({
        query: text,
        variables,
      })
    );
  return data;
}

// TODO: graphql multipart request spec に則った実装.
//       Relay の良さげな実装が出たら入れ替えたほうが良さそう.
//  ref: https://github.com/jaydenseric/graphql-multipart-request-spec
// TODO: axiosを1.3.4に上げるとFormDataがうまく送信できなくなるので調査・対応が必要
async function fetchMultipartGraphQL(
  params: RequestParameters,
  variables: Variables,
  _cacheConfig: CacheConfig,
  uploadables: UploadableMap
) {
  const formData = new FormData();
  formData.append(
    "operations",
    JSON.stringify({ query: params.text, variables })
  );
  const map: Record<string, string[]> = {};
  Object.keys(uploadables).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
      map[key] = [key];
    }
  });
  formData.append("map", JSON.stringify(map));

  Object.keys(uploadables).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
      const file = getFile(uploadables[key] as File);
      formData.append(key, file, file.name);
    }
  });
  const { data }: { data: AxiosResponse<ApiResponse> } =
    await axiosInstance.post(apiEndpoint, formData, {
      timeout: 1800000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  return data;
}

// FIXME: 本来は下記の formData への追記記述でいけるはずだけど、うまくいかないので上の方法で実行している.
//        おそらく react-native/Expo/Relay 辺りの問題だと思われるので version up などで直り次第修正する.
//        以下事象:
//        axios/fetch/xhr それぞれ試したが multipart/form-data として認識されてはいて
//        binary data 以外は送信できたが binary data のみ飛ばなかった.
//        (それぞれの方法全部で binary data が飛ばないことを wireshark で確認済みなので client の問題っぽい)
// formData.append(key, uploadables[key], (uploadables[key] as File).name);
function getFile(data: File) {
  if (Platform.OS === "web") {
    return data;
  }
  return {
    name: data.name,
    type: data.type,
    uri: data.name,
  };
}

async function retryWithtokenRefresh(response: AxiosResponse<ApiResponse>) {
  const tokenSet = await refreshToken(relayEnvironment);
  const newConfig = { ...response.config };

  if (newConfig.headers != null) {
    newConfig.headers.Authorization = `Bearer ${tokenSet.refreshToken.token}`;
  }
  const result = await axiosInstance.request(newConfig);
  return result;
}

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
async function fetchRelay(
  params: RequestParameters,
  variables: Variables,
  _cacheConfig: CacheConfig,
  uploadables?: UploadableMap | null
) {
  if (uploadables != null) {
    return fetchMultipartGraphQL(params, variables, _cacheConfig, uploadables);
  }
  return fetchGraphQL(params.text as string, variables);
}

async function handleError(
  error: ResponseError | undefined,
  config?: InternalAxiosRequestConfig,
  response?: AxiosResponse
) {
  try {
    const errorCode = error?.extensions?.code ?? "Unknown";
    const errorMessage = error?.message ?? "Unknown error occurred";

    const detailedError: DetailedErrorType = {
      code: errorCode as ErrorCodeType,
      message: errorMessage,
      request: config != null ? collectRequestInfo(config) : undefined,
      response,
      error,
    };

    switch (errorCode) {
      case "UnsupportedVersion":
        await handleSetError({
          ...detailedError,
          code: "Upgrade",
          message: "アプリケーションのアップデートが必要です",
          severity: "warning",
        });
        break;
      case "Unauthorized":
      case "InvalidToken":
        await handleSetError({
          ...detailedError,
          code: "Expired",
          message: "認証情報が無効です",
          severity: "warning",
        });
        break;
      case "Unknown":
        await handleSetError({
          ...detailedError,
          severity: "error",
        });
        break;
      default:
        throw new Error(JSON.stringify(error));
    }
  } catch (e: unknown) {
    const result = resolveError(e);
    await handleSetError({
      code: "ServiceDown",
      message: result.message,
      error,
      severity: "error",
    });
  }
}

// Export a singleton instance of Relay Environment configured with our network function:
const relayEnvironment = new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
});

export default relayEnvironment;
