import { graphql, fetchQuery } from "react-relay/hooks";
import { initializeUserQuery } from "@generated/initializeUserQuery.graphql";
import { PagingFavorite } from "@constants/App";
import RelayEnvironment from "../../RelayEnvironment";

const query = graphql`
  query initializeUserQuery($first: Int!) {
    influencer {
      id
      databaseId
    }
    ...FavoritesPagination @arguments(first: $first)
  }
`;

export default async function initializeUser(): Promise<{
  readonly databaseId: number;
  readonly id: string;
}> {
  const result = await new Promise<{
    readonly databaseId: number;
    readonly id: string;
  }>((resolve, reject) => {
    fetchQuery<initializeUserQuery>(RelayEnvironment, query, {
      first: PagingFavorite,
    }).subscribe({
      next: async ({ influencer }) => resolve(influencer),
      error: (error: unknown) => reject(error),
    });
  });
  return result;
}
