import { commitMutation, graphql } from "react-relay/hooks";
import { pushTokenMutation } from "@generated/pushTokenMutation.graphql";
import { getPushTokenAsync } from "@lib/util/pushNotification";
import { sendError } from "@lib/util/logger";
import ExceptionError from "@lib/errors/exceptionError";
import RelayEnvironment from "../../RelayEnvironment";

const pushTokenQuery = graphql`
  mutation pushTokenMutation($token: String!) {
    savePushToken(input: { token: $token }) {
      token
    }
  }
`;

export default async function savePushToken(): Promise<void> {
  const pushToken = await getPushTokenAsync();
  if (pushToken === null) {
    return;
  }
  const param = {
    token: pushToken,
  };
  await new Promise<void>((resolve) => {
    commitMutation<pushTokenMutation>(RelayEnvironment, {
      mutation: pushTokenQuery,
      variables: param,
      onCompleted: () => resolve(),
      onError: (err) => {
        sendError(new ExceptionError("savePushToken error", err));
        resolve();
      },
    });
  });
}
