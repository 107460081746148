import React from "react";
import { TouchableOpacity } from "react-native";
import { navigationRef, navigate } from "@navigation/navigate";
import type { BottomTabBarButtonProps } from "@react-navigation/bottom-tabs/src/types";

const WorkTabButton = React.memo((props: BottomTabBarButtonProps) => {
  const { style, children } = props;
  const currentPath = navigationRef.current?.getCurrentRoute()?.name;

  return (
    <TouchableOpacity
      accessibilityLabel={props.accessibilityLabel}
      accessibilityRole={props.accessibilityRole}
      accessibilityState={props.accessibilityState}
      onPress={() =>
        navigate("Root", {
          screen: "Todo",
          params: { screen: "workings" },
        })
      }
      style={[
        style,
        {
          opacity:
            currentPath === "workings" || currentPath === "recruiting"
              ? 1
              : 0.5,
        },
      ]}
    >
      {children}
    </TouchableOpacity>
  );
});

WorkTabButton.displayName = "WorkTabButton";

export default WorkTabButton;
